export const importanceLevelTooltips = [
  'EurLex Cases',
  "Key cases are judgments delivered since the inception of the new court in 1998 which have been selected and set as the official 'case-law' in the Case Reports.",
  'Level 1 = High importance, meaning all judgements which had made a significant contribution to the development of case-law, but has not been included in the Case Reports.',
  'Level 2 = Medium importance, meaning other judgments that, while not making a significant contribution to the case-law, they go beyond merely applying existing case law.',
  'Level 3 = Low importance, meaning judgments of very little legal interest. This could be judgments that simply apply existing case-law, friendly settlements and strike-outs.',
];
export const caseDetailTooltips = [
  'Non-Violation = Filter in or out judgments where the Court has not found a violation of the Convention or a Protocol Article.',
  'Violation = Filter in or out judgments where the Court has found a violation of the Convention or a Protocol Article.',
  'Miscellaneous = Filter in or out judgments where there has neither been found a violation, or a non-violation. This could for example be a friendly settlement, strike-out or just satisfaction.',
  'Saved in List = Filter in or out judgments you have saved. These can be found in the list on the left-hand sidebar.',
];